import '../styles/menu.css';
import '../styles/contact-us-form.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import HubSpotContactUsForm from '../components/hubSpotContactUsForm';
import  HDImg from '../images/buble.svg';
import  call1 from '../images/call-24-px.png';
import  droparrow from '../images/DownArrow2.svg';
import  email from '../images/email.svg';
import  email1 from '../images/ic-email-24-px.png';
import  iconback from '../images/icon-back-black.svg';
import  iconorange from '../images/icon-back-orange.svg';
import  phone from '../images/phone.svg';
import * as commonStyles from '../styles/head.module.css';
import AboutContent from './aboutHeader';
import InsuranceCards from './InsuranceLinks';
import InsurancePartners from './InsurancePartners';

let maxY;

const Menu = ( { logo, alt } ) => {

  const [showAbout, setAbout] = useState( false );
  const [showHome, setHome] = useState( false );
  const [showLife, setLife] = useState( false );
  const [showAuto, setAuto] = useState( false );
  const [active, setActive] = useState( 0 );
  const [showNav, toggleNav] = useState( true );
  const [modal, setModal] = useState( false );


  let innerWidth = 992;
  // const { innerWidth } = window;
  if ( typeof window !== 'undefined' ) {
    innerWidth = window.innerWidth;
  }

  const closeMenu = ( e ) => {
    const element = document.getElementById( 'menu-nav' );
    if ( e.target !== element && !element.contains( e.target ) ) {
      document.body.removeEventListener( 'click', closeMenu );
      toggleNav( false );
      setActive( 0 );
    }
  };

  const showModal = ( e ) => {
    const modalBody = document.querySelector( '.modal-body' );

    if( !( e.target === modalBody || !e.target.contains( modalBody ) ) ){
      setModal( false );
    }
  };

  useEffect( () => {
    if( active ) {
      document.body.addEventListener( 'click', closeMenu );
    }
  }, [active] );

  useEffect( () => {
    if( showNav &&  innerWidth < 991 ){
      document.body.addEventListener( 'click', closeMenu );
    }
  }, [showNav] );

  useEffect( () => {
    if ( innerWidth < 991 ) {
      toggleNav( false );
      document.body.addEventListener( 'click', closeMenu );
    }
  }, [innerWidth] );

  return (
    <nav id="menu-nav">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
          <nav className={`navbar navbar-expand-md fixed-top bgFFF ${showAbout ? '' : 'border-btm-paleGray'}`}>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" style={{ color: 'black' }}>
              </span>
            </button>

            <div id="navbarSupportedContent" hidden/>

            <div className="menuNav menuNavUse" id="navbarCollapse">
              <div className={commonStyles.hdContainer + ' navbar-nav hide-sm '
            + `${showNav ? 'hidden' : 'no-border'}`}>
                <img style={{ marginLeft: '12px', marginBottom: '5px' }} src="https://cdn.getmybubble.com/images/new-quote-flow/bubble-logo.svg" alt="Bubble Logo"/>

                <Link style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 0,
                  justifyContent: 'center',
                  padding: '0px 0 0 20px'
                }}
                to="/">
                  <img src={logo} style={{
                    width: '45%',
                    maxWidth: '250px',
                    height: 'auto',
                    marginBottom: 0
                  }} id="logoBubble" alt={alt}/>
                </Link>
              </div>

              <div className={`navbar-nav navHeight ${showNav ? '' : 'hidden'}`}>
                <div className="navBox">
                  <div style={{ padding: '15px 0px' }} className={`${active === 1 ? 'orangeBtnActive' : ''}`} >
                    <a onClick={() => { active === 1 ? setActive( 0 ) : setActive( 1 ); setAbout( !showAbout ); }}
                      tabIndex={0} role="button"> About
                      <img className="show-sm"
                        src={showAbout ? iconorange : iconback}
                        onClick={() => { active === 1 ? setActive( 0 ) : setActive( 1 ); setAbout( !showAbout ); }}
                        tabIndex={0} alt="button" role="button" />
                    </a>
                  </div>

                  <div className="d-sm-block d-md-none">
                    {showAbout && <AboutContent />
                    }
                  </div>

                  <div style={{ padding: '15px 0px' }} className={`${active === 2 ? 'orangeBtnActive' : ''}`}>
                    <a onClick={() => {
                      active === 2 ? setActive( 0 ) : setActive( 2 );
                      setHome( !showHome );
                    } }
                    tabIndex={0} role="button">Insurance
                      <img className="show-sm" src={showHome ? iconorange : iconback} alt="button"
                        onClick={ () => {
                          active === 2 ? setActive( 0 ) : setActive( 2 ); setHome( !showHome );
                        } }
                        tabIndex={0} role="button" />
                    </a>

                    {/* <div className="show-sm">
                      <img src={showHome ? iconorange : iconback} alt="button"
                        onClick={ () => {
                          active === 2 ? setActive( 0 ) : setActive( 2 ); setHome( !showHome );
                        } }
                        tabIndex={0} role="button" />
                    </div> */}
                  </div>

                  <div className="d-sm-block d-md-none">
                    {showHome && <InsurancePartners />}
                  </div>

                  <div style={{ padding: '15px 0px' }} className={`${active === 3 ? 'orangeBtnActive' : ''}`}>
                    <a onClick={() => {
                      active === 3 ? setActive( 0 ) : setActive( 3 );
                      setLife( !showLife );
                    } }
                    id="tools"
                    tabIndex={0} role="button">Tools
                      <img className="show-sm" src={showLife ? iconorange : iconback} alt="button"
                        onClick={() => { active === 3 ? setActive( 0 ) : setActive( 3 ); setLife( !showLife ); }}
                        tabIndex={0} role="button" />
                    </a>

                    {/* <div className="show-sm">
                      <img src={showLife ? iconorange : iconback} alt="button"
                        onClick={() => { active === 3 ? setActive( 0 ) : setActive( 3 ); setLife( !showLife ); }}
                        tabIndex={0} role="button" />
                    </div> */}
                  </div>

                  <div className="d-sm-block d-md-none">
                    { showLife && <InsuranceCards /> }
                  </div>

                  <div style={{ padding: '15px 0px' }} className={`${active === 4 ? 'orangeBtnActive' : ''}`} >
                    <a onClick={( e ) => {
                      e.preventDefault();
                      active === 4 ? setActive( 0 ) : setActive( 4 );
                      window.location.href = 'https://partnerwithus.getmybubble.com';
                    }}
                    title='Partner With Us'
                    tabIndex={0} role="button">Partner With Us
                    </a>

                    <div className="show-sm"></div>
                  </div>
                </div>

                <div className="section10 d-md-none1111" id="contactUs"
                  style={{ pointerEvents: 'all', cursor: 'pointer' }} onClick={() => setModal( true )} data-bs-toggle="modal" data-bs-target="#hubSpotFormMenu">
                  <div > <div className="d-inherit" style={{ width: '72px' }}>
                    <img src={email} className="phone-img-align" alt="phone"/> </div>

                  <div className="sh-mob">Form  </div>
                  </div>
                </div>

                {
                  modal &&
                <div
                  onClick={showModal}
                  className={`modal fade  ${ modal ? 'show' : ''}`}
                  id="hubSpotFormMenu"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-modal="true"
                  role="dialog"
                  style={{ display: 'block', paddingLeft: '0' }}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=> setModal( false )}>
                        </button>
                      </div>

                      <div className="modal-body">
                        <HubSpotContactUsForm />
                      </div>
                    </div>
                  </div>
                </div>
                }

                {/*
                <div id="formParent">

                  { modal &&
                  <div className="modal fade" id="hubSpotFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                          </button>
                        </div>

                        <div className="modal-body">
                          {/* Contact Modal

                          <HubSpotContactUsForm />
                        </div>
                      </div>
                    </div>
                  </div>

                </div> */}

                <div className="dropdown1 d-md-none1111 section30">
                  <div className="menu-with-arrow">
                    <div className="sh-brw d-inherit" style={{ width: '72px' }}>
                      <img src={phone} className="phone-img-align dropbtn" alt=""/>
                    </div>

                    <div className="sh-mob" data-bs-toggle="collapse" data-href="#collapseExample99" role="button" aria-expanded="false"             aria-controls="collapseExample">
                      <div className="sh-mob1">Contact Us
                        <img src={droparrow} alt="arrow"/>
                      </div>

                    </div>
                  </div>

                  <div className="dropdown-content aboutPage">
                    <div className=" flexRow justify-content-between makeColumn">
                      <div id="collapseExample99" >
                        <div className="flexColumn touch">
                          <div className="title">Get in touch with us</div>

                          <div className="flexRow">
                            <img src={call1} alt="call" />

                            <div className="makeColumn">
                              <div className="learnMore fwNormal MobileLftPadding"> (833) 900-BUBB</div>
                            </div>
                          </div>

                          <div className="flexRow">
                            <img src={email1} alt="email" />

                            <div className="makeColumn">
                              <a className="MobileLftPadding" href="mailto:hello@getmybubble.com" target="_blank" rel="noopener noreferrer">
                                <div className="learnMore fwNormal"> hello@getmybubble.com </div>
                              </a>

                              <p className="para" style={{ opacity: 1 }}> (24/7, at your service)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="button" className={ 'btn-quote ' + commonStyles.hdLoginBttn}>Get a Quote</button>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className={commonStyles.hdMianContainermob}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <nav className="navbar navbar-expand-md  fixed-top">
                <a className="navbar-brand" href="/">
                  <img src={HDImg} className={commonStyles.HDImg} style={{ minHeight: '20px', minWidth: '80px' }} alt="logo"/>
                </a>

                <button onClick={() => toggleNav( !showNav )} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon">
                  </span>

                  {!showNav ?
                    <svg width="17" height="12" xmlns="http://www.w3.org/2000/svg">
                      <line x1="1" x2="16" y1="2" y2="2" stroke="#2a2727" strokeWidth="2" />

                      <line x1="1" x2="16" y1="6" y2="6" stroke="#2a2727" strokeWidth="2" />

                      <line x1="1" x2="16" y1="10" y2="10" stroke="#2a2727" strokeWidth="2" />
                    </svg>
                    :
                    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
                      <line x1="1" x2="14" y1="1" y2="14" stroke="#2a2727" strokeWidth="2" />

                      <line x1="14" x2="1" y1="1" y2="14" stroke="#2a2727" strokeWidth="2" />
                    </svg>
                  }
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="hide-sm" onMouseLeave={e => { e.persist(); maxY = Math.max( e.nativeEvent.pageY, maxY || 0 ); }}>
          {active === 1 &&  <AboutContent /> }

          { active === 2 && <InsurancePartners /> }

          { active === 3 && <InsuranceCards /> }
        </div>
      </div>
    </nav>
  );
};


export default Menu;


